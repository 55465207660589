<template>
    <div class="pt-6">

        <v-row v-if="activesSubscription">
            <v-col cols="12" md="12" class="grey lighten-4">
                <div>
                    <h3 class="mb-2"></h3>

                    <div class="">
                        <div class="princing-card  container  " :class="''">
                            <div>
                                <div class=" ">
                                    <div class="d-flex justify-space-between">
                                        <h2 class="card-title">{{ activesSubscription.plan }} plan</h2>

                                    </div>
                                    <div class="d-flex mt-3">

                                        <!-- <div class="princing mr-3 ">
                                            <span class="unit">{{ '$' }}</span>
                                            <span class="price">{{ activesSubscription.amount }}</span>
                                        </div> -->
                                        <div class="princing-description d-flex align-center  ">
                                            <div>
                                                <p class="mb-0 ">
                                                    <span class="ml-">From {{ activesSubscription.startingDate }} to {{
                                                        activesSubscription.expirationDate }}
                                                    </span>

                                                </p>

                                            </div>
                                        </div>
                                    </div>


                                </div>


                            </div>
                        </div>
                        <div class="princing-detail container">
                            <p class="princing-detail-title mt-2">Plan Detail</p>
                            <div>
                                <ul class="list ">
                                    <li class="list-item grey lighten-4"><span class="">✓</span>
                                        {{ activesSubscription.maxWorkspace }} Workspace(s)
                                    </li>
                                    <li class="list-item grey lighten-4"><span class="">✓</span>
                                        {{ activesSubscription.maxMemberPerWorkspace }} Members /
                                        Workspace
                                    </li>
                                    <li class="list-item grey lighten-4"><span class="">✓</span>
                                        {{ activesSubscription.maxProjectPerWorkspace }} Projects /
                                        Workspace
                                    </li>
                                    <li class="list-item grey lighten-4"><span class="">✓</span>
                                        {{ activesSubscription.maxModelPerProject }} Models /
                                        Project
                                    </li>
                                </ul>
                            </div>
                        </div>
                       <div>
                        <div v-for="pricingItem,o of activesSubscription.pricings" :key="o" class="princing-detail container">
                            <p class="princing-detail-title mt-2">{{ pricingItem.pricing.featureDetailTitle }}</p>
                            <div>
                                <ul class="list ">
                                    <li class="list-item" v-for="feature, i of pricingItem.features" :key="i"><span
                                            class="check">✓</span> {{ feature.name }} </li>
                                </ul>
                            </div>

                        </div>
                       </div>
                    </div>

                </div>
            </v-col>


        </v-row>
    </div>
</template>


<script>
import { methods } from "@/utils/constant.js";
import { mapGetters, mapActions } from "vuex";


export default {
    components: {},
    data() {
        return {
            loading: false
        }
    },
    computed: {
        ...mapGetters(["activesSubscription"]),

    },
    methods: {
        ...mapActions(["getActiveSubscription"]),
        goTo(link) {
            this.$router.push(link);

        },
        async initialize() {
            try {
                this.loading = true;

                await this.getActiveSubscription()
            } catch (error) {
                console.log("initialize ", error);
                methods.dispatchError(this.$store);
            } finally {
                this.loading = false;

            }
        },

    },
    mounted() {
        this.initialize();
    },
};
</script>